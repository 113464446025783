.advantages-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    margin: 0 auto 50px;
    list-style: none;
    padding: 0;
}

.advantages-list__item {
    width: 20%;
    padding: 28px;
}

.advantages-list__item-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.advantages-list__item-icon {
    width: 110px;
    height: 110px;
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-bottom: 20px;
}

.advantages-list__item--profitable .advantages-list__item-icon {
    background-image: url('../../img/icoon-winstgevend.svg');
}

.advantages-list__item--reduction .advantages-list__item-icon {
    background-image: url('../../img/icoon-co2.svg');
}

.advantages-list__item--easy .advantages-list__item-icon {
    background-image: url('../../img/icoon-eenvoudig.svg');
}

.advantages-list__item--silent .advantages-list__item-icon {
    background-image: url('../../img/icoon-geluidsarm.svg');
}

.advantages-list__item-title {
    font-family: Roboto condensed, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: none;
    color: #009f3c;
    margin: 0;
    max-width: 260px;
}

.advantages-list__item-description {
    font-family: Roboto;
    font-size: 1.4rem;
    font-weight: 400;
    color: #686867;
    margin: 0;
    display: none;
}

.explore__subheading {
    &.about-us {
        max-width: 677px;
        font-size: 22px;
        line-height: 40px;
    }
}

@media screen and (max-width: 1400px) {
    .advantages-list__item {
        padding: 14px;
    }

    .advantages-list__item-icon {
        width: 126px;
        height: 105px;
        background-size: 126px 105px;
    }

    .advantages-list__item-title {
        font-size: 1.2rem;
    }

    .advantages-list__item-description {
        font-size: 1rem;
    }
}

@media screen and (max-width: 930px) {
    .advantages-list__item-icon {
        width: 107px;
        height: 89px;
        background-size: 107px 89px;
    }
}
