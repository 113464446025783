.container {
    width: calc(100% - 25px);
    margin: 0 auto;
}

.slick-slider * {
    outline: none !important;
}

.variable-width .slick-slide p {
    background: blue;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
}

.content {
    padding: 20px;
    margin: auto;
    width: 90%;
}

.slick-vertical .slick-slide {
    height: 180px;
}
.slick-arrow {
}
.slick-arrow:hover {
}
.slick-prev {
    z-index: 10;
    left: -10px !important;
}
.slick-next {
    z-index: 10;
    right: -10px !important;
}

.mobile-design,
.tablet-design {
    .container {
        max-width: 768px;
    }

    .slick-prev {
        z-index: 10;
        left: 10px !important;
        background-color: white;
        transform: scale(1.3);
        border-radius: 9999px;

        &:before {
            color: #005281 !important;
        }
    }
    .slick-next {
        z-index: 10;
        right: 10px !important;
        background-color: white;
        transform: scale(1.3);
        border-radius: 9999px;

        &:before {
            color: #005281 !important;
        }
    }
}
