@media screen and (max-width: 750px) {
    .team__list-desktop {
        display: none;
    }

    .team__list-mobile {
        display: flex;
        flex-direction: column;
        background-color: white;
        margin: 0 auto;
    }

    .team__wrapper {
        width: 100%;
    }

    .team__heading {
        display: inline-block;
        font-size: 1.5rem;
        margin: 30px 20px 0;
    }

    .team-member-mobile {
        margin-top: 50px;
    }

    .team-member-mobile__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 0 0 20px;
    }

    .team-member-mobile__info {
        text-align: left;
    }

    .team-member-mobile__heading {
        color: #00a329;
        font-family: 'Roboto Condensed';
        font-size: 1rem;
        font-weight: 700;
        text-transform: none;
        margin: 12px 0 15px;
    }

    .team-member-mobile__name {
        font-family: 'Roboto', sans-serif;
        font-size: 1.1rem;
        font-weight: bold;
        color: #009f3c;
        margin: 0 0 0;
    }

    .team-member-mobile__function {
        font-family: 'Roboto', sans-serif;
        font-style: italic;
        font-size: 0.9rem;
        font-weight: 500;
        color: #686867;
    }

    .team-member-mobile__photo {
        display: inline-block;
        width: auto;
        height: 150px;
    }

    .team-member-mobile__contact {
        display: flex;
        justify-content: space-evenly;
        background-color: #005281;
        padding: 10px 20px 0;
        &.mobile-contact-tfs {
            background-color: #00517e;
        }
    }

    .team-member__contact-mobile {
        width: 55px;
        padding-top: 15px;
    }

    .team__direction {
        font-size: 1.2rem;
        margin: 25px 28px 18px;
    }

    .contact__photo {
        height: 300px;
    }

    .mobile-tfs {
        img {
            height: 110px;
        }
    }
}
