.exchange {
    display: flex;
    align-items: center;
    width: 100%;
    height: 450px;
    background-image: url('../../img/sell-our-hybride-generator-become-a-dealer.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .exchange-desktop-only {
        display: block;
    }

    .exchange-mobile-only {
        display: none;
    }

    .exchange__wrapper {
        //display: grid;
        grid-gap: 1em;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto;
        align-items: center;
        width: 96%;
        max-width: 1420px;
        margin: 0 auto;
    }

    .exchange__logo {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        justify-self: start;
    }

    .exchange__description-wrapper {
        //grid-column: 2 / 3;
        //grid-row: 1 / 2;
        justify-self: center;
        text-align: center;
        margin: auto;

        p {
            color: white;
            font-size: 4rem;
            margin: auto;
            text-shadow: 2px 2px black;
            font-family: 'Roboto condensed';
        }
    }

    .exchange__description {
        font-family: 'Roboto condensed';

        font-size: 1.7rem;
        font-weight: 700;
        text-transform: none;
        margin: 0;
    }

    .exchange__description--first {
        color: #525251;
    }

    .exchange__description--second {
        color: white;
    }

    .exchange__description--mobile {
        display: none;
    }

    .exchange__link {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        justify-self: end;
    }

    .exchange__link {
        display: block;
        width: 100px;
        height: 100px;
        background-image: url('../../img/pijlbutton-inruildeals.svg');
        background-repeat: no-repeat;
        background-size: 100px 100px;
        background-position: 0 0;
    }

    .exchange__link-mobile {
        display: none;
    }
}

@media screen and (max-width: 1320px) {
    .exchange {
        .exchange__logo {
            width: 260px;
        }
        .exchange__description {
            font-size: 1.4rem;
        }
        .exchange__link {
            width: 75px;
            height: 75px;
            background-size: 75px 75px;
        }
    }
}

@media screen and (min-width: 750px) {
    .exchange {
        cursor: pointer;

        .mobile-dealer {
            display: none;
        }
    }
}
