@media screen and (max-width: 1050px) {
    .page-header {
        min-height: unset;
        height: 25px;

        &:not(.page-header--index) {
            box-shadow: 0 0 5px #2d2d2d;
        }
    }

    .main-nav__toggle {
        display: block;
        transition-duration: 0.5s;
        box-sizing: border-box;
        margin-left: 5px;
        margin-top: -17px;
    }

    .main-nav__toggle:hover {
        transform: scale(1.1);
        transition-duration: 0.5s;
        outline: none;
    }
    .main-nav__toggle:focus {
        outline: none;
    }

    .main-nav__toggle:active {
        opacity: 0.5;
    }

    .main-nav {
        padding-top: 30px;
    }

    .main-nav__toggle-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 32px;
        height: 26px;
        box-sizing: border-box;
        margin-left: 0;
        margin-top: 0;
    }

    img.hamburger {
        width: 35px;
    }

    .main-nav__wrapper {
        display: none;

        &.main-nav__wrapper--mobile {
            display: block;

            .main-nav__list {
                display: flex;
                flex-direction: column;
                animation: slideFromTop 0.4s;
                z-index: 25;
                height: 100vh;
                //width: 200px;
                background-color: #005281;
                opacity: 0.9;
                position: fixed;
                top: 55px;
                left: 0;

                li {
                    width: 100%;
                    background-color: #005281;
                    padding: 10px 0;
                    margin: 0;
                    font-size: 17px;
                }
            }
        }
    }

    @keyframes slideFromTop {
        0% {
            left: -100vw;
        }
        100% {
            left: 0;
        }
    }

    .page-header__logo {
        top: 25px;
    }

    .page-header__logo-image {
        width: 124px;
        height: auto;
        margin-top: -12px;
    }

    .page-header__button-question {
        top: 0;
        right: 20px;
        z-index: 100;
        width: 34px;
        height: 52px;
    }
}

@media screen and (max-width: 530px) {
    .page-header__button-question {
        right: 5px;
    }
}

@media screen and (max-width: 440px) {
    .page-header__button-question {
        top: -2px;
    }
}
