.dropdown.dropdown-language {
    position: absolute;
    top: 25px;
    left: 0;
    text-transform: none;

    ul {
        list-style-type: none;
        padding: 5px;
        margin: 5px;

        li {
            padding: 5px;
            margin: 5px;
            cursor: pointer;
            color: white;
            text-decoration: none;
        }
    }
}

//@media screen and (max-width: 1201px) {
.dropdown.dropdown-language {
    display: none;
}
//}
