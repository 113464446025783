.comparison {
    display: grid;
    grid-template-columns: 300px auto 300px;
    grid-template-rows: auto;
    width: 100%;

    border: 2px solid #009f3c;
    border-radius: 10px;
    text-align: left;
    margin: 60px auto 60px;
    box-shadow: 0 5px 15px 0 #cecece;
    max-width: 1310px;
}

@media screen and (min-width: 1361px) {
    .comparison__read-more--mobile {
        display: none;
    }
}

.comparison__model {
    position: relative;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    padding: 20px 15px;
}

.comparison__model::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 80%;
    top: 10%;
    right: 0;
    background-color: #009f3c;
}

.comparison__graph {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.rent {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}

.comparison__heading {
    font-family: 'Roboto Condensed';
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0.3px;
    color: #009f3c;
    margin: 0 0 26px;
}

.comparison__description {
    margin: 0 0 30px 0;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    color: #686867;
}

.comparison__read-more {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    /* font-weight: 600; */
    letter-spacing: 0.5px;
    text-transform: lowercase;
    color: white;
    background-color: #009f3c;
    border: 2px solid white;
    border-radius: 30px;
    padding: 6px 17px 6px;
    transition-duration: 0.5s;
}

.comparison__read-more:hover,
.comparison__read-more:focus {
    transform: scale(1.1);
    transition-duration: 0.5s;
}

.comparison__read-more:active {
    color: #009f3c;
    background-color: white;
    border: 2px solid #009f3c;
}

.comparison__title {
    color: #686867;
}

.comparison__graph {
    padding: 20px 20px 20px;
}

.comparison__heading--inline-block {
    display: inline-block;
}

.comparison__versus {
    display: inline-block;
    padding: 5px 10px;
    font-family: Roboto, sans-serif;
    font-size: 0.8rem;
    color: white;
    border-radius: 50px;
}

.colorful-text {
    display: inline-flex;
    float: right;

    span {
        font-weight: normal !important;
    }
}

.comparison__versus--green {
    background-color: #009f3c;
}

.comparison__versus--red {
    background-color: #ff321f;
}

.comparison__versus--regular {
    color: #686867;
}

.comparison__wrapper {
    display: inline-grid;
    min-width: calc(50% - 15px);
}

.comparison__wrapper--upper {
    margin-bottom: 26px;
}

.comparison__bar {
    height: 25px;
    width: 0;
    background-color: #ff2907;
    background-image: linear-gradient(to right, white, #ff2907);
    border-radius: 5px;
    margin-bottom: 5px;
}

.comparison__bar-inner {
    height: 100%;
    border-radius: 5px 0 0 5px;
}

.comparison__bar--outer-fuel {
    &.animate {
        @media screen and (min-width: 651px) {
            animation: progressOuterFuel 1.5s ease-in-out normal forwards;
        }
        @media screen and (max-width: 650px) {
            animation: progressOuterFuelMobile 1.5s ease-in-out normal forwards;
        }
    }
}

.comparison__bar--outer-co2 {
    &.animate {
        @media screen and (min-width: 651px) {
            animation: progressOuterCO 1.5s ease-in-out normal forwards;
        }
        @media screen and (max-width: 650px) {
            animation: progressOuterCOMobile 1.5s ease-in-out normal forwards;
        }
    }
}

.comparison__bar-inner--fuel {
    &.animate {
        @media screen and (min-width: 651px) {
            animation: progressInnerFuel 0.5s ease-in-out normal forwards;
        }
        @media screen and (max-width: 650px) {
            animation: progressInnerFuelMobile 0.5s ease-in-out normal forwards;
        }
    }
}

.comparison__bar-inner--co2 {
    &.animate {
        @media screen and (min-width: 651px) {
            animation: progressInnerCO2 0.5s ease-in-out normal forwards;
        }
        @media screen and (max-width: 650px) {
            animation: progressInnerCO2Mobile 0.5s ease-in-out normal forwards;
        }
    }
}

@keyframes progressOuterFuel {
    from {
        width: 0;
    }
    to {
        width: 440px;
    }
}
@keyframes progressOuterCO {
    from {
        width: 0;
    }
    to {
        width: 530px;
    }
}
@keyframes progressInnerFuel {
    from {
        width: 0;
    }
    to {
        width: 100px;
    }
}
@keyframes progressInnerCO2 {
    from {
        width: 0;
    }
    to {
        width: 40px;
    }
}

@keyframes progressOuterFuelMobile {
    from {
        width: 0;
    }
    to {
        width: 82%;
    }
}
@keyframes progressOuterCOMobile {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
@keyframes progressInnerFuelMobile {
    from {
        width: 0;
    }
    to {
        width: 21%;
    }
}
@keyframes progressInnerCO2Mobile {
    from {
        width: 0;
    }
    to {
        width: 7%;
    }
}

.animation-box--top {
    margin-bottom: 25px;
}

.comparison__bar-inner {
    background-color: #009f3c;
    background-image: linear-gradient(to right, #76cb95, #06a140);
}

.comparison__title {
    font-weight: 500;
    margin: 0 0 5px;
}

.comparison__number-container {
    display: flex;
    justify-content: space-between;
}

.comparison__number {
    font-weight: 500;
}

.comparison__number--small {
    color: #009f3c;
}

.comparison__number--big {
    color: #ff2907;
}

.rent {
    padding: 20px 20px 20px;
    background-color: #009f3c;
}

.comparison__heading--mobile {
    display: none;
}

.comparison__heading--rent {
    text-transform: none;
    color: white;
    margin-bottom: 12px;
    font-size: 1.25rem;
}

.comparison__subheading {
    color: white;
    font-size: 1.2rem;
    margin: 0 0 30px;
}

.comparison__button {
    margin: 0 0 40px;

    &:hover {
        cursor: pointer;
    }
}

.comparison__contact-container--mobile {
    display: none;
}
