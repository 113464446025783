// minimal resolutions
// mobile = 320;
// tablet = 750;
// desktop = 1200;

// Mobile
@media screen and (max-width: 750px) {
    .tablet-design {
        display: none;
    }
    .desktop-design {
        display: none;
    }
}

// Tablet
@media screen and (min-width: 751px) and (max-width: 1360px) {
    .mobile-design {
        display: none;
    }
    .desktop-design {
        display: none;
    }
}

// Desktop
@media screen and (min-width: 1361px) {
    .mobile-design {
        display: none;
    }
    .tablet-design {
        display: none;
    }
}

//.mobile-design {
//    display: none;
//}
