.model-list__btn {
    display: none;
}

.explore {
    background-color: white;
    width: 100%;
    margin-top: 40px;
}

.why-us {
    margin-top: 145px;
}

.explore__wrapper {
    width: 82%;
    max-width: 1715px;
    margin: 0 auto;
}

@media screen and (min-width: 750px) and (max-width: 1615px) {
    .explore__wrapper {
        width: 100%;
        max-width: 1300px;
    }
}

.explore__heading-desktop {
    font-family: 'Roboto Condensed';
    text-transform: capitalize;
    letter-spacing: 0.3px;
    color: #009f3c;

    font-size: 3.1rem;
    font-weight: 100;
    //color: #005281;
}

.explore__subheading {
    font-family: Roboto;
    font-size: 1.9rem;
    font-weight: 400;
    color: #686867;
    margin: 0 auto 25px;
}

.model-list {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 auto;
    max-width: 1310px;
}

.model-list__item img {
    transition-duration: 0.5s;
    opacity: 0.5;
    height: auto;
    padding-bottom: 15px;
}

.model-list__item:hover img,
.model-list__item:focus img {
    transform: scale(1.1) translate(0, -10px);
    transition-duration: 0.5s;
    cursor: pointer;
    opacity: 1;
}

.model-list__link {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    //text-transform: none;
    letter-spacing: 0.3px;
    color: grey;
}

.model-list__item:hover a,
.model-list__item:focus a {
    color: #009f3c;
}

.model-list__item--active {
    img {
        transform: scale(1.1);
        transform-origin: 50% 100%;
        cursor: unset;
        opacity: 1;
    }
    a {
        color: #009f3c;
    }
}
