@media screen and (max-width: 750px) {
    .exchange {
        width: 100%;
        height: 550px;
        //height: calc(100vh - 80px);
        background-color: #a6d1ce;
        background-image: url('../../img/sell-our-hybride-generator-become-a-dealer-mobile.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;

        .exchange-desktop-only {
            display: none;
        }

        .exchange-mobile-only {
            display: block;
        }

        .exchange__wrapper {
            display: flex;
            flex-direction: column;
            width: 260px;
            margin: 0 auto;

            p {
                display: none;
            }
        }

        .exchange__logo {
            width: 244px;
            height: auto;
            margin: 0 auto 48px;
        }

        .exchange__description {
            font-size: 1.2rem;
            letter-spacing: 0.5px;
            text-align: center;
            font-family: 'Roboto Condensed';
        }

        .exchange__description--first {
            color: #ff0000;
            margin-bottom: 28px;
        }

        .exchange__description--second {
            display: none;
        }

        .exchange__description--mobile {
            color: #009f3c;
            margin-bottom: 70px;
            display: block;
        }

        .exchange__description--mobile span {
            color: #005281;
        }

        .exchange__link {
            display: none;
        }

        .exchange__link-mobile {
            display: inline-block;
            text-decoration: none;
            color: #009f3c;
            font-family: Roboto;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 700;
            background-color: #ffffff;
            padding: 11px 13px;
            //border: 2px solid white;
            border-radius: 50px;
            transition-duration: 0.5s;
        }

        .exchange__link-mobile:hover,
        .exchange__link-mobile:focus {
            transform: scale(1.1);
            transition-duration: 0.5s;
        }

        .exchange__link-mobile:active {
            opacity: 0.3;
            transition-duration: 0.5s;
        }

        .mobile-dealer {
            display: block;
            color: white;

            h2 {
                font-size: 2rem;
                font-weight: 100;
                font-family: 'Roboto Condensed';
            }

            p {
                display: block;
                font-size: 1rem;
            }
        }
    }
}
