.intro__heading-mobile {
    display: none;
}

.intro {
    color: white;
    margin: 0;
    width: 100%;
    //height: 950px;
    //height: 100vh;
}

.background-image {
    position: absolute;
    top: 0px;
    z-index: -1;
    width: 100%;
    //height: 950px;
    height: 100%;
    background-color: #0092ff;
    background-image: url('../../img/green-sustainable-clean-energy-with-the-green-generator-buy-or-sell-a-hybride-generator-and-become-a-dealer.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.intro__heading {
    font-family: 'Roboto Condensed', 'Roboto';
    text-transform: capitalize;
    margin: 0;
    padding-top: 70px;
    font-size: 3.1rem;
    font-weight: 100;
    color: #005281;
}

.intro__subheading {
    font-family: 'Roboto';
    font-size: 1.9rem;
    font-weight: 400;
    color: #005281;
    //display: none;
}

.intro__next {
    position: absolute;
    bottom: 20px;
    width: 80px;
    height: 50px;
    margin-left: -40px;
    padding: 0;
    transition-duration: 0.5s;
    animation: float 2s ease-in-out infinite;
}

.intro__next:hover,
.intro__next:focus {
    transform: scale(1.3);
    transition-duration: 0.5s;
    outline: none;
}

.intro__next:active {
    opacity: 0.3;
}

.intro__next::before {
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    display: block;
    background-color: white;
    width: 50px;
    height: 4px;
    border-radius: 50px;
    transform: rotate(45deg);
}

.intro__next::after {
    position: absolute;
    top: 50%;
    left: 32px;
    content: '';
    display: block;
    background-color: white;
    width: 50px;
    height: 4px;
    border-radius: 50px;
    transform: rotate(-45deg);
}

// Animations
@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-10px);
    }
    100% {
        transform: translatey(0px);
    }
}
