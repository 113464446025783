@media screen and (max-width: 750px) {

  .contact__address-container {
    padding: 17px 15px;
    width: 80%;
    text-align: center; /*in de design is text-align: left*/
  }

  .contact__heading {
    font-size: 0.9rem;
    margin-bottom: 16px;
  }

  .contact__address,
  .contact__phone,
  .contact__mail {
    font-size: 0.9rem;
  }

  .contact__address {
    margin-bottom: 20px;
  }

  .contact__phone {
    margin-bottom: 0;
  }

  .contact__logo {
    width: 66%;
    height: auto;
    margin: 48px auto 30px;
  }
}