.onze-visie-page {
    h1 {
        text-transform: none;
        font-family: 'Roboto Condensed';
        font-size: 3.1rem;
        font-weight: 100;
        letter-spacing: 0.3px;
        color: #009f3c;
    }
    p {
        color: #686867;
    }
    i {
        color: white;
        font-size: 45px;
    }

    .onze-visie-background-image {
        margin-top: 30px;
        width: 100%;
        height: 800px;
        background-image: url('../../img/onze-visie/de-groene-aggregaat-hybride-aggregaat-off-grid-stroom-oplossing-gemaakt-in-nederland-groot.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .box {
        p {
            color: white;
            font-weight: bold;
        }
    }

    .trigger {
        max-width: 750px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding-left: 15px;
        margin: 30px auto 0;
        box-shadow: 6px 5px 5px #cccccc;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }

    .grey-box {
        .trigger {
            background-color: #686868;
        }

        .content-visible {
            border: 3px solid #686868;
        }
    }
    .green-box {
        .trigger {
            background-color: #00a228;
        }
        .content-visible {
            border: 3px solid #00a228;
        }
    }
    .blue-box {
        .trigger {
            background-color: #005281;
        }
        .content-visible {
            border: 3px solid #005281;
        }
    }

    .horizontal-rule {
        height: 2px;
        width: calc(100% - 30px);
        background-color: #00a228;
        margin: 0 auto;
    }

    .content-visible {
        opacity: 1;
        max-height: 1500px;
        transition-duration: 0.6s;
        width: 100%;
        max-width: 751px;
        margin: 0 auto 15px;
        padding: 4px;
        box-shadow: 6px 5px 5px #cccccc;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        .box-content {
            p {
                color: #686868;
                font-weight: normal;
                text-align: left;
                padding: 12px;
                margin-top: 0;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.5px;
            }
            img {
                width: 350px;
            }

            h3 {
                color: #00a228;
                text-align: left;
                padding-left: 15px;
                text-transform: none;
                font-size: 1.5rem;
            }

            blockquote {
                color: #00a228;
                font-style: italic;
                font-size: 1.5rem;
            }

            .header-with-icon {
                display: flex;
                padding: 20px 20px 0 20px;

                img {
                    width: 75px;
                }
                h3 {
                    margin-bottom: 0;
                    margin-top: 10px;
                    margin-left: 15px;
                }
                h5 {
                    font-style: italic;
                    color: #00a228;
                    padding-left: 15px;
                    font-weight: normal;
                    text-align: left;
                    margin-top: 0;
                    margin-left: 15px;
                    font-size: 1.1rem;
                }
            }
        }
    }
    .content-hidden {
        opacity: 0;
        max-height: 0;
        transition-duration: 0.3s;
        max-width: 751px;
        margin: 5px auto;
        padding: 0;
        border: none;

        .box-content {
            pointer-events: none;

            p {
                color: #686868;
                font-weight: normal;
                text-align: left;
                padding: 12px;
                margin-top: 0;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.5px;
            }
            img {
                width: 350px;
            }

            h3 {
                color: #00a228;
                text-align: left;
                padding-left: 15px;
                text-transform: none;
                font-size: 1.5rem;
            }

            blockquote {
                color: #00a228;
                font-style: italic;
                font-size: 1.5rem;
            }

            .header-with-icon {
                display: flex;
                padding: 20px 20px 0 20px;

                img {
                    width: 75px;
                }
                h3 {
                    margin-bottom: 0;
                    margin-top: 10px;
                    margin-left: 15px;
                }
                h5 {
                    font-style: italic;
                    color: #00a228;
                    padding-left: 15px;
                    font-weight: normal;
                    text-align: left;
                    margin-top: 0;
                    margin-left: 15px;
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .onze-visie-page .box p {
        font-size: 15px;
        text-align: left;
    }

    .onze-visie-page .onze-visie-background-image {
        height: 300px;
    }

    .onze-visie-page section.first-section p {
        font-size: 13px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .onze-visie-page section.first-section h1 {
        background-color: #00a329;
        color: white;
        margin: 0;
        padding: 15px 0;
        font-size: 18px;
        //box-shadow: inset 0 3px 5px 0 #4e4e4e;
    }

    .onze-visie-page .trigger {
        margin: 10px 20px 0;
    }

    .onze-visie-page .content-visible {
        width: unset;
        margin: 0 20px;
        max-height: 2700px;
    }
    .onze-visie-page .content-visible .box-content img {
        width: 250px;
    }
    .onze-visie-page .content-visible .box-content blockquote {
        color: #00a228;
        font-style: italic;
        font-size: 1rem;
        margin: 4px;
    }

    .onze-visie-page .content-visible .box-content p {
        font-size: 13px;
    }

    .onze-visie-page .content-visible .box-content .header-with-icon {
        flex-direction: column;
        align-items: center;
    }
    .onze-visie-page .content-visible .box-content .header-with-icon h3 {
        font-size: 17px;
        margin-bottom: 5px;
        margin-top: 15px;
        margin-left: 0;
        text-align: center;
    }
    .onze-visie-page .content-visible .box-content .header-with-icon h5 {
        font-size: 15px;
        margin-bottom: 5px;
        margin-top: 15px;
        margin-left: 0;
        text-align: center;
    }

    .onze-visie-page .content-hidden {
        width: unset;
        margin: 10px 20px 0;
        overflow: scroll;
    }
    .onze-visie-page .content-hidden .box-content img {
        width: 250px;
    }
    .onze-visie-page .content-hidden .box-content blockquote {
        color: #00a228;
        font-style: italic;
        font-size: 1rem;
        margin: 4px;
    }
    .onze-visie-page .content-hidden .box-content p {
        font-size: 13px;
    }
    .onze-visie-page .content-hidden .box-content .header-with-icon {
        flex-direction: column;
        align-items: center;
    }
    .onze-visie-page .content-hidden .box-content .header-with-icon h3 {
        font-size: 17px;
        margin-bottom: 5px;
        margin-top: 15px;
        margin-left: 0;
        text-align: center;
    }
    .onze-visie-page .content-hidden .box-content .header-with-icon h5 {
        font-size: 15px;
        margin-bottom: 5px;
        margin-top: 15px;
        margin-left: 0;
        text-align: center;
    }
}
