@media screen and (max-width: 770px) {
    .background-image {
        position: absolute;
        top: 0px;
        z-index: -1;
        width: 100%;
        height: unset;
        min-height: 100%;
        background-color: #0092ff;
        background-image: url('../../img/green-sustainable-clean-energy-with-the-green-generator-buy-or-sell-a-hybride-generator-and-become-a-dealer-mobile.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .intro {
        //height: calc(100vh - 25px);
    }

    .intro__heading {
        font-size: 1.3rem;
        margin: 0 27px;
    }

    .intro__subheading {
        font-family: 'Roboto Condensed';
        font-size: 20px;
    }

    .intro__next {
        bottom: 15px;
    }

    .intro__heading-mobile {
        display: block;
        color: #ffffff;
        font-family: Roboto, sans-serif;
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto 0 15px;
        position: absolute;
        bottom: 45px;
        width: calc(100% - 20px);
        height: 50px;
        padding: 0;
    }

    .intro__next::before {
        position: absolute;
        top: 50%;
        left: 20px;
        content: '';
        display: block;
        background-color: white;
        width: 30px;
        height: 3px;
        border-radius: 50px;
        transform: rotate(45deg);
    }

    .intro__next::after {
        position: absolute;
        top: 50%;
        left: 40px;
        content: '';
        display: block;
        background-color: white;
        width: 30px;
        height: 3px;
        border-radius: 50px;
        transform: rotate(-45deg);
    }
}
