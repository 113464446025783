@media screen and (max-width: 1360px) {
    //@media screen and (max-width: 1200px) {

    .comparison__read-more--desktop {
        display: none;
    }

    .comparison {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        width: 100%;
        border: none;
        box-shadow: none;
        max-width: 810px;
        margin: 0 auto;
    }

    .comparison__title {
        width: 100%;
    }

    .comparison__model {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding: 0 20px;
    }

    .comparison__model::after {
        display: none;
    }

    .comparison__heading--hidden {
        display: none;
    }

    .comparison__heading--inline-block {
        font-size: 1.1rem;
    }

    .comparison__description {
        font-size: 1.1rem;
        line-height: 1.3rem;
    }

    .comparison__button {
        -webkit-appearance: none;
        appearance: none;
        border: 0;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        background-color: #005281;

        display: flex;
        justify-content: space-around;

        .material-icons {
            color: white;
            font-size: 35px;
        }

        &:hover {
            background-color: white;
            transition-duration: 0.5s;
            cursor: pointer;
            .material-icons {
                color: #005281;
            }
        }
    }

    .comparison__button--right {
        float: right;
        margin-right: 15px;
    }

    .comparison__button--left {
        float: left;
        margin-left: 15px;
    }

    .comparison__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .comparison__read-more--mobile {
        font-size: 0.9rem;
        text-transform: none;
        color: #686867;
        background-color: white;
        border-color: #009f3c;
    }

    .comparison__graph {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        margin-bottom: 12px;
        padding: 0 20px;
        max-width: 750px;
    }

    .comparison__versus {
        background-color: transparent;
        font-size: 1rem;
        padding: 0;
        font-weight: bold;
        margin-bottom: 16px;
    }

    .comparison__versus--regular {
        display: none;
    }

    .comparison__versus--green {
        color: #009f3c;
        margin-left: 0;
        margin-right: 12px;
    }

    .comparison__versus--green::before {
        display: inline-block;
        height: 13px;
        width: 13px;
        border-radius: 10px;
        background-color: #009f3c;
    }

    .comparison__versus--red {
        color: #ff321f;
    }

    .comparison__versus--red::before {
        content: '';
        display: inline-block;
        height: 13px;
        width: 13px;
        border-radius: 10px;
        background-color: #ff321f;
        margin-right: 4px;
        vertical-align: text-top;
    }

    .comparison__versus--green::before {
        content: '';
        display: inline-block;
        height: 13px;
        width: 13px;
        border-radius: 10px;
        background-color: #009f3c;
        margin-right: 4px;
        vertical-align: text-top;
    }

    .comparison__bar {
        height: 20px;
    }

    .comparison__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .comparison__number {
        font-size: 15px;
    }

    .comparison__number-container--fuel {
        //width: 82%;
    }

    .comparison__number-container--co2 {
        width: 100%;
    }

    .comparison__bar {
        border-radius: 2px;
    }

    .comparison__bar-inner {
        height: 100%;
        border-radius: 2px 0 0 2px;
    }

    .comparison__bar-inner--fuel {
        width: 21%;
    }

    .comparison__bar-inner--co2 {
        width: 7%;
    }

    .comparison__bar--outer-fuel {
        width: 82%;
    }

    .comparison__bar--outer-co2 {
        width: 100%;
    }

    .rent {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        width: 100%;
        padding: 0;
        background-color: white;
        margin-top: 15px;
    }

    .comparison__heading--mobile {
        display: block;
        color: #00a228;
        font-family: Roboto, sans-serif;
        font-size: 1.1rem;
        font-weight: 700;
        text-align: center;
    }

    .comparison__heading--rent,
    .comparison__subheading--desktop {
        display: none;
    }

    .comparison__contact-container--desktop {
        display: none;
    }

    .comparison__contact-container--mobile {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 320px;
        margin: 10px auto 17px;
    }

    .colorful-text {
        display: contents;
        float: left;
    }
}

.model-image-wrapper {
    &.model-5 {
        img {
            max-width: 200px;
        }
    }
    &.model-30 {
        img {
            max-width: 250px;
        }
    }

    &.model-100 {
        img {
            max-width: 320px;
        }
    }
    img {
        width: 80%;
        margin: 15px auto;
        max-width: 300px;
    }
}
