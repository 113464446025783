.page-header {
    position: relative;
    width: 100%;
    background-color: #005281;
    text-transform: lowercase;
    min-height: 0;
    font-family: Roboto, sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    padding-bottom: 30px;
}

.page-header--index {
    background-color: transparent;
    padding-bottom: 0;
}

.main-nav {
    padding-top: 0px;
}

.main-nav__toggle {
    display: none;
}

.main-nav__wrapper {
    width: 960px;
    margin: 0 auto 0 calc(50% - 525px);
}

@media screen and (max-width: 1020px) {
    .main-nav__wrapper {
        width: 100%;
    }
}

.main-nav__list {
    display: flex;
    margin: 0 auto;
    width: 100%;
    align-items: center;
    position: relative;
    top: 15px;
}

.main-nav__list-item {
    padding: 10px;
    font-size: 21px;
}

.main-nav__list-item--action {
    margin-left: auto;
}

.page-header__logo {
    display: block;
    position: absolute;
    top: 35px;
    left: calc(50% - 18px);
    transform: translate(-50%, -30%);
    width: 230px;
    z-index: 5;
}

.main-nav__list-item a {
    color: white;
}

.page-header__button-question {
    position: fixed;
    top: 29px;
    right: 25px;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-transform: lowercase;
    color: white;
    background-color: #005281;
    padding: 10px;
    border: 2px solid white;
    border-radius: 50px;
    transition-duration: 0.5s;
    z-index: 2;
}

@media screen and (max-width: 1490px) {
    .page-header__button-question {
        border: none;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        color: transparent;
        background-color: transparent;
        padding: 0;
        width: 52px;
        height: 80px;
        top: 50px;
        background-image: url('../../src/img/stel-een-vraag-icoon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
    }

    .page-header__button-question:active {
        background-color: transparent; /*werkt niet??*/
    }
}

@media screen and (max-width: 1490px) {
    .page-header__button-question {
        top: 10px;
        right: 10px;
    }
}

.page-header__button-question--invisible {
}

.page-header__button-question:hover {
    transform: scale(1.1);
    transition-duration: 0.5s;
    outline: none;
    cursor: pointer;
}

.page-header__button-question:active {
    color: #005281;
    background-color: white;
    border-color: #005281;
    outline: none;
}

@media screen and (max-width: 1490px) {
    .page-header__button-question:active {
        background-color: transparent;
        opacity: 0.5;
    }
}
