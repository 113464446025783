body {
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    background-color: white;
    overflow: hidden auto;
}

/* Visually hidden */

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

/* Site-list */

.site-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Site-link */

.site-link {
    text-decoration: none;
    color: inherit;
}

/* Site-buttton */

.site-button {
    display: inline-block;
    padding: 10px;
    border-radius: 50px;
}

/* Button-reset */

.button-reset {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

/* Contact-button */

.contact-button {
    background-color: white;
    color: #686867;
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    padding: 10px 20px 10px 50px;
}

.contact-button--phone {
    background-image: url('../../img/telefoon.svg');
    background-repeat: no-repeat;
    background-size: 22px 21px;
    background-position: 22px 50%;
}

.contact-button--mail {
    background-image: url('../../img/bericht.svg');
    background-repeat: no-repeat;
    background-size: 24px 19px;
    background-position: 18px 46%;
}

.contact-button-mobile {
    position: relative;
    display: inline-block;
    background-color: white;
    border-radius: 50px;
    color: #686867;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.07px;
    text-decoration: none;
    padding: 9px 50px 9px 16px;
    width: 70px;
    border: 2px solid #005281;

    &:hover {
        cursor: pointer;
    }
}

.contact-button-mobile--phone {
    background-image: url('../../img/telefoon.svg');
    background-repeat: no-repeat;
    background-size: 22px 21px;
    background-position: 82px 50%;
}

.contact-button-mobile--mail {
    background-image: url('../../img/bericht.svg');
    background-repeat: no-repeat;
    background-size: 24px 19px;
    background-position: 82px 46%;
}
