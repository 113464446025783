@media screen and (max-width: 750px) {
    .why-us {
        //display: none;
        margin-top: 22px;

        h2.explore__heading-desktop {
            display: block;
            font-size: 18px;
        }
        p.about-us {
            display: block;
            font-size: 15px;
            line-height: 20px;
            margin: 0 auto;
            padding: 10px 25px;
            br {
                display: none;
            }
        }
    }

    .explore__wrapper {
        margin: 0;
        width: 100%;
    }

    .explore__heading-desktop {
        display: none;
    }
    .explore__subheading {
        display: none;
    }
    .model-list__item {
        display: none;
    }

    .model-list__item:hover,
    .model-list__item:focus {
        transform: none;
    }

    .model-list .model-list__item--visible {
        display: block;
        width: 88%; /*werkt niet?*/
        margin: 0 auto;
    }

    .model-list__image {
        width: 58%;
        height: auto;
        margin: 0 auto;
    }

    .model-list__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .model-list__btn {
        position: relative;
        display: inline-block;
        background-color: #005281;
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        transition-duration: 0.5s;
    }

    .model-list__btn:hover,
    .model-list__btn:focus {
        outline: none;
        transform: scale(1.2);
        transition-duration: 0.5s;
    }

    .model-list__btn:active {
        opacity: 0.5;
        transition-duration: 0.5s;
    }

    .model-list__arrow {
        position: absolute;
        display: block;
        width: 14px;
        height: 2px;
        background-color: white;
        border-radius: 5px;
    }

    .model-list__btn--previous .model-list__arrow--1 {
        transform: rotate(-45deg);
        top: 33%;
        left: 7px;
    }

    .model-list__btn--previous .model-list__arrow--2 {
        transform: rotate(45deg);
        top: 62%;
        left: 7px;
    }

    .model-list__btn--next .model-list__arrow--1 {
        transform: rotate(45deg);
        top: 33%;
        right: 7px;
    }

    .model-list__btn--next .model-list__arrow--2 {
        transform: rotate(-45deg);
        top: 62%;
        right: 7px;
    }

    .model-image-wrapper {
        max-width: 190px;

        &.model-5 {
            img {
                max-width: 110px;
            }
        }
    }

    .comparison {
        .rent {
            background-color: #005281;

            .comparison__heading--mobile {
                color: white;
            }
        }
        .colorful-text {
            span {
                font-size: 13px;
            }
        }

        .comparison__title {
            font-size: 14px;
        }

        .comparison__number {
            font-size: 16px;
        }

        .comparison__description {
            margin: 0 0 5px 0;
            font-size: 0.9rem;
            line-height: 1.2rem;
        }

        .comparison__heading--mobile {
            font-size: 0.9rem;
        }

        .comparison__heading .comparison__heading--inline-block {
            font-size: 1rem;
        }

        .comparison__heading {
            margin: 0 0 15px;
        }
        .comparison__number {
            font-size: 13px;
        }
    }
}
