@media screen and (max-width: 950px) {
    .green-deals {
        background-image: url('../../img/groene-inruildeals-achtergrond.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .offer {
            display: none;
        }

        .discount__wrapper--desktop {
            display: none;
        }

        .exchange__wrapper {
            display: block;
            width: 100%;
            min-width: unset;
            padding: 0;
            box-sizing: border-box;
        }

        .discount {
            width: 95%;
            text-align: center;
            margin: 0 auto;
        }

        .discount__logo {
            width: 244px;
            height: auto;
            margin: 0 auto 46px;
        }

        .discount__wrapper--mobile {
            display: flex;
            flex-direction: column;
            font-size: 1.3rem;
            margin-bottom: 70px;
        }

        .discount__red {
            margin-bottom: 30px;
        }

        .exchange__button-mobile {
            display: block;
            text-transform: none;
            background-color: #0691f9;
            color: white;
            border: 2px solid white;
            border-radius: 50px;
            font-family: Roboto;
            font-size: 1rem;
            font-weight: 700;
            padding: 16px 20px;
            margin: 0 auto;
        }
    }
}
