.exchange__button-mobile {
    display: none;
}

.discount__wrapper--mobile {
    display: none;
}

.green-deals {
    display: flex;
    align-items: center;
    width: 100%;
    //height: calc(100vh - 80px);
    background-color: #a6d1ce;
    background-image: url('../../img/achtergrond-greendeals.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .exchange__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 76%;
        min-width: 1260px;
        margin: 0 auto;
        padding: 0;
    }

    .discount {
        display: flex;
        flex-direction: column;
        width: 45%;
    }

    .discount__logo {
        margin-bottom: 40px;
        width: 400px;
    }

    .discount__wrapper {
        font-family: 'Roboto Condensed';
        font-weight: 700;
        text-transform: none;
    }

    .discount__wrapper--desktop {
        text-align: left;
        font-size: 2.2rem;
    }

    .discount__red {
        display: block;
        margin-bottom: 70px;
        color: #ff0000;
    }

    .discount__green {
        color: #00a328;
    }

    .discount__blue {
        color: #005281;
    }

    .offer {
        width: 50%;
        background-color: white;
        text-align: left;
        box-sizing: border-box;
        padding: 25px;
        max-width: 650px;
        margin-top: -15px;
    }

    .offer__heading {
        color: #00a328;
        font-family: 'Roboto Condensed';
        font-size: 1.9rem;
        font-weight: 700;
        text-transform: none;

        margin: 0;
    }

    .offer__description {
        color: #797979;
        font-family: Roboto;
        font-size: 1rem;
        font-weight: 400;
    }
}

.request-form {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 40px 40px 40px 40px 40px 40px 40px;
    grid-gap: 20px;
    margin: 0;
}

.request-form__input {
    border: 2px solid #009f3c;
    color: #009f3c;
    font-family: Roboto;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 12px;
}

.request-form__input--first-name {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.request-form__input--last-name {
    grid-column: 2 / -1;
    grid-row: 1 / 2;
}

.request-form__input--company {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
}

.request-form__input--phone {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}

.request-form__input--mail {
    grid-column: 2 / -1;
    grid-row: 3 / 4;
}

.request-form__input--brand {
    grid-column: 1 / -1;
    grid-row: 4 / 5;
}

.request-form__input--year {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
}

.request-form__input--hours {
    grid-column: 3 / -1;
    grid-row: 5 / 6;
}

.request-form__label {
    cursor: pointer;
}

.request-form__label--inner {
    grid-column: 1 / 3;
    grid-row: 6 / 7;
}

.request-form__label {
    align-self: center;
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 400;
    color: #797979;
}

.request-form__label--outer {
    grid-column: 3 / -1;
    grid-row: 6 / 7;
    justify-self: end;
}

.request-form__label span {
    background-color: #009f3c;
    color: white;
    font-size: 0.8rem;
    padding: 3px 5px;
    border-radius: 5px;
    vertical-align: middle;
}

.request-form__file:focus + .request-form__label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.request-form__file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.request-form__input--textarea {
    grid-column: 1 / -1;
    grid-row: 7 / 11;
    resize: none;
}

.request-form__submit {
    justify-self: start;
    font-family: Roboto, sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: lowercase;
    color: white;
    background-color: #009f3c;
    border-radius: 30px;
    padding: 5px 25px 5px;
    margin-top: 5px;
    transition-duration: 0.5s;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
}

.request-form__submit:hover,
.request-form__submit:focus {
    transform: scale(1.1);
    transition-duration: 0.5s;
    outline: none;
}

.request-form__submit:active {
    color: #009f3c;
    background-color: white;
    border: 2px solid #009f3c;
}

@media screen and (max-width: 1320px) {
    .green-deals {
        .exchange__wrapper {
            width: 96%;
            min-width: unset;
        }
    }
    .request-form {
        grid-template-columns: 30% 24% 38%;
    }

    .request-form__input {
        padding: 3px;
    }
}

@media screen and (max-width: 1320px) {
    .green-deals {
        .discount__wrapper--desktop {
            font-size: 1.9rem;
        }

        .discount__logo {
            width: 80%;
        }

        .offer__description {
            font-size: 0.8rem;
        }

        .offer {
            min-height: unset;
        }
    }
    .request-form__input {
        font-size: 0.8rem;
    }

    .request-form__label {
        font-size: 0.8rem;
    }
}
