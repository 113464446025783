$green: #009f3c;
$blue: #005281;

.model-list-menu {
    display: flex;
    justify-content: space-between;
    margin: 25px 120px;

    a {
        text-decoration: none;
        color: $green;
        font-weight: bold;
        text-transform: none;
        font-size: 26px;
        font-family: 'Roboto Condensed', sans-serif;

        &.active {
            border-bottom: 3px solid $blue;
        }
    }
}

.model-page-container {
    display: flex;
    width: 100%;

    .comparison__contact-container--desktop {
        display: block !important;

        .comparison__button {
            background-color: white;
            color: #686867;
            font-size: 1rem;
            font-weight: 700;
            text-decoration: none;
            padding: 10px 20px 10px 50px;
            margin: 0 0 40px;
            width: 200px;
            height: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .info-block {
        max-width: 450px;
        min-height: 650px;
        background-color: $green;
        padding: 20px;
        color: white;
        text-align: left;

        h2 {
            font-size: 20px;
            text-transform: uppercase;
        }
        p {
            font-size: 16px;
        }

        .contact-info {
            //position: absolute;
            margin-top: 112px;
            width: 320px;
        }
    }

    .showcase-block {
        display: flex;
        justify-content: space-evenly;
        position: relative;
        margin: 0 15px 0 35px;
        padding: 10px 0;

        .showcase-label {
            color: black;
            border: 2px solid $green;
            border-radius: 8px;
            background-color: white;
            width: 230px;
            padding: 12px 20px;
            text-align: left;
            margin-bottom: 40px;
            font-size: 15px;
            position: relative;
            box-shadow: 5px 5px 5px #aaaaaa;

            &:hover {
                cursor: pointer;
            }

            &.active-label {
                color: white;
                background-color: $green;
                display: block;
                box-shadow: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                span {
                    font-family: 'Roboto Condensed', sans-serif;
                }

                &:after {
                    content: '';
                    color: #009f3c;
                    position: absolute;
                    font-size: 75px;
                    top: -2px;
                    right: -20px;
                    width: 0;
                    height: 0;
                    border-top: 23px solid transparent;
                    border-bottom: 23px solid transparent;
                    border-left: 18px solid #009f3c;
                }
            }
        }

        .showcase-image-container {
            width: 700px;
            height: auto;
            margin-left: 65px;

            img {
                width: 100%;
                box-shadow: 5px 5px 5px #aaaaaa;
                border-radius: 5px;
            }
        }

        .model-specs {
            //display: none;
            position: absolute;
            bottom: 65px;
            border: 2px solid $green;
            border-radius: 15px;
            color: #686868;
            box-shadow: 5px 5px 5px #aaaaaa;
            display: flex;
            justify-content: space-between;

            .facts {
                padding: 30px;
                text-align: left;
                display: flex;
                flex-wrap: wrap;
                width: 80%;

                div {
                    display: flex;
                    flex-direction: column;
                    font-size: 18px;

                    &.left-specs {
                        margin-right: 25px;
                    }
                }
            }

            h2 {
                color: $green;
                text-transform: none;
                margin: 0 0 15px;
                width: 100%;
                font-size: 20px;
            }

            a {
                display: none;
                color: white;
                background-color: $blue;
                font-weight: bold;
                border-radius: 15px;
                border: 1px solid $blue;
                padding: 4px 15px;
                float: right;
                cursor: pointer;
                text-transform: none;
                text-decoration: none;
                font-size: 18px;
                //display: inline;

                .material-icons {
                    font-size: 29px;
                }

                .specs-download {
                    display: inline;

                    vertical-align: super;
                    padding-left: 10px;
                }
            }

            .weekly-savings {
                background-color: $green;
                color: white;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
                padding: 15px;
                text-align: left;
                width: 20%;

                h3 {
                    color: white;
                    margin: 0 0 25px;
                    font-weight: 500;
                }

                p {
                    font-size: 14px;
                    line-height: 25px;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}

.mobile-showcase-labels {
    display: none;

    .mobile-label {
        color: white;
        background-color: #686868;
        border-radius: 8px;
        padding: 10px 15px;
        max-width: 300px;
        margin: 10px auto;
    }

    .mobile-label-dots {
        width: 80px;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;

        div {
            border-radius: 9999px;
            width: 8px;
            height: 8px;
            border: 1px solid #009f3c;
            background-color: white;

            &.active {
                background-color: #009f3c;
            }
        }
    }
}

@media screen and (min-width: 1650px) {
    .model-page-container .showcase-block {
        margin: 10px auto !important;
    }
    .model-page-container .info-block {
        padding: 30px;
    }
    .model-page-container .showcase-block .showcase-image-container {
        width: 750px;
        height: 400px;
        margin-left: 65px;
    }

    .model-page-container .showcase-block .showcase-label {
        margin-bottom: 50px;
    }
    .model-page-container .showcase-block .model-specs {
        bottom: 30px;
    }
    .model-page-container .info-block .contact-info {
        margin-top: 150px;
    }
}

@media screen and (min-width: 1900px) {
    .model-page-container .showcase-block .showcase-image-container {
        width: 810px;
    }
}

@media screen and (max-width: 1500px) {
    .showcase-block {
        width: calc(100% - 440px);
    }
    .showcase-image-container {
        margin-left: 60px;
    }
    .showcase-label {
        width: 100%;
    }
    .info-block {
        width: 350px;
    }
}

@media screen and (max-width: 1350px) {
    .model-page-container .showcase-block {
        margin: 0 auto !important;
        flex-direction: column;
        justify-content: space-between;
    }

    .model-page-container .showcase-block .showcase-image-container {
        margin: 0 auto !important;
        width: 100%;
        max-width: 800px;
    }

    .showcase-image-container {
        margin-left: 45px;
    }
    .showcase-label {
        display: none !important;
    }
    .model-page-container .showcase-block .desktop-labels {
        display: none;
    }
    .mobile-showcase-labels {
        display: block;
    }
    .model-page-container .showcase-block .model-specs {
        position: unset;
    }

    .model-page-container .showcase-block .model-specs a {
        padding: 0 4px;
        font-size: 13px;
    }

    .model-page-container .showcase-block .model-specs .facts .specs-download {
        font-size: 13px;
        font-weight: normal;
        padding-left: 4px;
    }
    .model-page-container .showcase-block .model-specs a .material-icons {
        font-size: 20px;
    }
    .model-page-container .showcase-block .model-specs .facts {
        padding: 15px;
    }
}

.mobile-info-block {
    display: none;
}
.mobile-model-specs {
    display: none;
    flex-direction: column;
    align-self: center;
    width: 100%;
    max-width: 381px;
    min-width: 265px;
    margin-bottom: 25px;
    margin-top: 15px;

    background-color: $green;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 5px 5px 5px #686868;

    h2 {
        font-size: 16px;
        text-transform: none;
    }

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    h3 {
        font-size: 14px;
        text-transform: none;
        margin-top: 25px;
    }
    p {
        margin: 0;
    }

    a {
        margin-top: 15px;
        display: none;
    }

    > * {
        color: white;
    }
}

.model-list-menu.not-on-mobile {
    a {
        &:hover {
            cursor: pointer;
        }
        &.active-model {
            border-bottom: 3px solid $blue;
        }
    }
}

.not-on-desktop {
    display: none;
}

// Full Mobile
@media screen and (max-width: 950px) {
    // temp
    .model-page-container .showcase-block .model-specs {
        display: none;
    }

    .page-main .not-on-mobile {
        display: none;
    }

    .mobile-model-specs {
        display: flex;
    }

    .model-page-container .info-block {
        display: none;
    }

    .model-page-container .showcase-block {
        width: calc(100% - 50px);
        max-width: 550px;
        height: auto;
    }

    .mobile-info-block {
        display: block;

        .animate-readmore {
            transition: max-height 0.25s ease-in;
            max-height: 500px;
            overflow: hidden;
        }
        .animate-readless {
            transition: max-height 0.25s ease-out;
            max-height: 55px;
            overflow: hidden;
        }

        .read-more {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            background-color: white;
            border: 3px solid $green;
            border-radius: 30px;
            padding: 8px 15px;
            margin-top: 10px;
            outline: none;
        }

        .interesse {
            color: $green;
            font-weight: bold;
            font-size: 18px;
            margin-top: 7px;
        }

        .model-contact-buttons {
            display: flex;
            justify-content: center;

            .team-member__contact-mobile {
                width: 58px;
            }

            a {
                border: 3px solid $green;
            }
        }
    }

    .model-list-menu {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        background-color: white;

        a {
            color: white;
            font-size: 14px;

            &.active-model {
                transform: scale(1.25);
                border-bottom: 3px solid black;
            }

            &:first-child {
                display: none;
            }
            &:last-child {
                display: none;
            }
        }
    }
}

.slick-slide {
    // The active model
    color: $green;
    min-height: 58px;
    line-height: 30px;
    text-transform: none;

    p {
        font-size: 14px;

        @media screen and (max-width: 500px) {
            font-size: 13px;
        }
    }

    &.slick-current + .slick-slide {
        font-weight: bold;

        p {
            transform: scale(1.5);
            transition: transform 0.2s ease-in-out;
        }
    }
}

.slick-next:before,
.slick-prev:before {
    color: $green !important;
}

// Extra maatwerk styling
.maatwerk {
    .model-list-menu a {
        color: #00517e;
    }
    .model-page-container .info-block {
        background-color: #00517e;
    }
    .model-page-container .showcase-block .model-specs .weekly-savings {
        background-color: #00517e;
    }
    .model-page-container .showcase-block .model-specs h2 {
        color: #00517e;
    }
    .model-page-container .showcase-block .model-specs {
        border: 2px solid #00517e;
    }
    .model-page-container .showcase-block .showcase-label {
        border: 2px solid #00517e;
    }
    .model-page-container .showcase-block .showcase-label.active-label {
        border: 2px solid #00517e;
        background-color: #00517e;

        &:after {
            color: #00517e;
            border-left-color: #00517e;
        }
    }


    .download-button-factsheet {
        display: none;
    }

    .download-button-factsheet,
    .model-page-container .showcase-block .model-specs a.download-button-factsheet {
        //display: none;
        visibility: hidden;
        -moz-backface-visibility: hidden;
        pointer-events: none;
    }

    .mobile-showcase-labels .mobile-label-dots {
        div {
            border-color: #00517e;
            &.active {
                background-color: #00517e;
            }
        }
    }

    // mobile maatwerk
    .model-list-menu.not-on-desktop {
        background-color: white;
    }
    .mobile-info-block .read-more {
        border-color: #00517e;
    }
    .mobile-info-block .interesse {
        color: #00517e;
    }
    .mobile-model-specs {
        background-color: #00517e;
    }

    .team-member__contact-mobile.contact-button-mobile.contact-button-mobile--mail,
    .team-member__contact-mobile.contact-button-mobile.contact-button-mobile--phone {
        border-color: #00517e !important;
    }
    .slick-slide {
        // The active model
        color: #00517e;
    }
    .slick-next:before,
    .slick-prev:before {
        color: #00517e !important;
    }
}
