.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
}

.contact__address-container {
    text-align: left;
    background-color: #00a329;
    color: white;
    border-radius: 30px;
    //padding: 30px 98px 30px 36px;
    padding: 25px;
    margin: 0 auto;
    box-shadow: 5px 6px 6px 0 #a9a9a9;
}

.contact__heading {
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0.45px;
    margin: 0 0 24px;
}

.contact__address,
.contact__phone,
.contact__mail {
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 400;
}

.contact__address {
    margin: 0 0 25px;
}

.contact__phone {
    margin: 0 0 10px;
}

.contact__mail {
    margin: 0;
}

.contact__logo {
    margin: 30px auto;
    width: 480px;
}

.contact__photo {
    width: 100%;
    height: 642px;
    background-image: url('../../img/contact/pand-de-groene-aggregaat.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
