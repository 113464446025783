@media screen and (max-width: 750px) {
    .advantages-list {
        margin: 0;
        flex-direction: column;
    }

    .advantages-list__item {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 150px;
        padding: 0;
    }

    .advantages-list__item-box {
        flex-direction: row;
        margin-left: 20px;
    }

    .advantages-list__item-container {
        width: 186px;
    }

    .advantages-list__item-title {
        text-align: left;
        font-size: 1.1rem;
        padding-top: 0;
    }

    .advantages-list__item-description {
        text-align: left;
        font-size: 0.9rem;
        display: block;
    }

    .advantages-list__item:nth-of-type(even) {
        background-color: #009f3c;
    }

    .advantages-list__item:nth-of-type(even) .advantages-list__item-title,
    .advantages-list__item:nth-of-type(even) .advantages-list__item-description {
        color: white;
    }

    .advantages-list__item-icon {
        width: 80px;
        height: 74px;
        background-size: 80px 74px;
        margin-right: 22px;
        margin-bottom: 0;
    }

    .advantages-list__item--reduction .advantages-list__item-icon {
        background-image: url('../../img/icoon CO2.svg');
    }

    .advantages-list__item--silent .advantages-list__item-icon {
        background-image: url('../../img/icoon geluidsarm.svg');
    }
}
