footer {
    display: block;
}

.page-footer {
    background-color: #525251;
    color: white;
    width: 100%;
    font-family: Roboto;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 24px;
}

.page-footer__list {
    list-style-type: none;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto;
    width: 73%;
    max-width: 1420px;
    padding: 35px 0 0;
    margin: 0 auto;
}

.page-footer__list-item--logo {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin-right: 80px;
    justify-self: start;
    align-self: start;
    margin-bottom: 60px;
}

.page-footer__list-item--adress {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    text-align: left;
    margin-right: 40px;
}

.page-footer__list-item--contact {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    text-align: left;
    margin-right: 40px;
}

.page-footer__list-item--social {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    justify-self: end;
}

.page-footer__social {
    display: flex;
    justify-content: space-between;
    width: 110px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.page-footer__list-item--links {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
}

.page-footer__links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 30px 0 30px;
    padding: 0;
    list-style-type: none;
}

.page-footer__links-item,
.page-footer__links2-item {
    border-right: 1px solid white;
    padding: 0 10px;
}

.page-footer__links-item:first-of-type,
.page-footer__links2-item:first-of-type {
    padding-left: 0;
}

.page-footer__links-item:last-of-type,
.page-footer__links2-item:last-of-type {
    border: none;
}

.page-footer__list-item--links2 {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
}

.page-footer__links2 {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.page-footer__list-item--copyright {
    grid-column: 4 / 5;
    grid-row: 3 / 4;
    justify-self: end;
}

@media screen and (max-width: 1320px) {
    .page-footer__list {
        width: 96%;
    }

    .page-footer__list-item--adress,
    .page-footer__list-item--contact {
        margin-right: 10px;
    }

    .page-footer__list-item--logo {
        margin-right: 20px;
    }
}

.page-footer__social-icon {
    fill: white;
    transition-duration: 0.4s;
    width: 50px;
}

.linked-in {
    &:hover {
        fill: lighten(#0077b5, 25%);
    }
}

.youtube {
    &:hover {
        fill: lighten(#ff0000, 25%);
    }
}

// Ipad PRO footer style
@media screen and (min-width: 1024px) and (min-height: 1366px) {
    .page-footer {
        height: 485px;
    }
}
