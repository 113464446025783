@keyframes slideFromRight {
    0% {
        right: -580px;
    }
    100% {
        right: 0;
    }
}

@keyframes slideFromLeft {
    0% {
        right: 0;
    }
    100% {
        right: -580px;
    }
}

.modal-open .modal {
    overflow-y: hidden !important;
}

.modal-question {
    position: fixed;
    overflow: hidden;
    top: 0;
    height: 100vh;
    box-sizing: border-box;
    background-color: white;
    color: #009f3c;
    //padding: 40px 80px 40px 40px;
    right: 0;
    transition-duration: 0.5s;

    .bug-free-scroll {
        padding: 40px 80px 40px 40px;
        overflow-y: scroll;
        max-height: 90%;
    }
}

.modal-question-open {
    animation: slideFromRight 0.5s;
}
.modal-question-close {
    animation: slideFromLeft 0.25s;
}

.modal.fade .modal-dialog {
}

.modal-body {
    position: unset;
    padding: unset;
}

.modal-question__button-close {
    color: #009f3c;
    background-color: white;
    border: 2px solid #009f3c;
    font-family: Roboto, sans-serif;
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: lowercase;
    border-radius: 30px;
    transition-duration: 0.5s;
    padding: 3px 10px;
    margin-bottom: 20px;
    width: 99px;
    text-align: left;
    display: block;

    i {
        float: left;
        margin-top: 1px;
    }
    span {
        float: right;
    }
}

.fade.modal.show {
    padding-right: 0 !important;
}

.modal-question__button-close:hover,
.modal-question__button-close:focus {
    transform: scale(1.1);
    transition-duration: 0.5s;
    outline: none;
}

.modal-question__heading {
    font-size: 1.3rem;
    text-transform: none;
    font-weight: 100;
    margin: 0 0 15px 0;
    text-align: left;
}

.question-form__intro {
    text-align: left;
}

.modal-question__wrapper {
    display: flex;
    justify-content: space-between;
    //align-items: flex-start;
}

.modal-question__contact {
    margin: 0;
    width: 180px;
    padding: 15px;
    background-color: #009f3c;
    color: white;
    border-radius: 15px;

    &:hover {
        cursor: pointer;
    }
}

.question-form__title {
    display: grid;
    grid-template-columns: 150px auto auto;
    grid-template-rows: auto auto auto auto auto;
    grid-gap: 15px;
    width: 100%;
    height: auto;
}

.question-form__input {
    grid-column: 1 / 4;
    border: 1px solid #009f3c;
    padding: 10px;
}

.question-form__submit {
    color: white;
    background-color: #009f3c;
    font-family: Roboto;
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: lowercase;
    transition-duration: 0.5s;
}

.question-form__submit:hover,
.question-form__submit:focus {
    transform: scale(1.1);
    transition-duration: 0.5s;
    outline: none;
}

.question-form__input--first-name {
    grid-column: 1 / 2;
}

.question-form__input--last-name {
    grid-column: 2 / 4;
}

.question-form__input--bericht {
    min-height: 120px;
}

textarea {
    resize: none;
    font-family: Roboto, sans-serif;
}

@media screen and (max-width: 580px) {
    .modal-question {
        position: fixed;
        top: 0;
        width: 100%;
        box-sizing: border-box;
        background-color: white;
        color: #009f3c;
        padding: 0;
        right: 0;
        transition-duration: 0.5s;

        .bug-free-scroll {
            padding: 10px;
        }
    }

    .question-form__title {
        grid-template-columns: 33% 30% 80px;
    }

    .modal-question__button-close {
        float: right;
    }
    .modal-question__heading {
        font-size: 1rem;
        max-width: 200px;
    }

    .modal-question__contact {
        padding: 5px;
        font-size: 13px;
    }

    .question-form__input--first-name {
        grid-column: 1 / 4;
    }
    .question-form__input--last-name {
        grid-column: 1 / 4;
    }

    .question-form__input {
        padding: 7px 8px;
    }

    .question-form__title {
        grid-gap: 10px;
    }

    .question-form__intro {
        text-align: left;
        padding: 0;
        margin-top: 20px;
        margin-bottom: 5px;
    }

    .question-form__input--bericht {
        min-height: unset;
    }
    .question-form__submit {
        font-size: 1rem;
        padding: 4px 10px;
        font-weight: 500;
    }

    //.modal-question__wrapper {
    //    max-width: 340px;
    //}
}

.modal-question__wrapper {
    width: 100%;
}
.contact-block {
    //width: 45%;
}

.modal-question__photo {
    width: 50%;

    img {
        width: 100%;
        height: 100%;
    }
}

.modal-small-intro {
    text-align: -webkit-left;
    text-align: left;
    text-align: -moz-left;
    font-size: 13px;
}

// Updated Code
.form-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;

    input,
    textarea {
        width: 100%;
        padding: 7px 10px;
        border: 1px solid #009f3c;
        color: #009f3c;
        margin: 4px 0;
        font-family: Roboto, sans-serif;
        outline: none;

        &:focus {
            border: 1px solid #0077b5;
            font-weight: bold;
        }

        &::placeholder {
            color: #aaaaaa;
        }
    }

    button {
        -webkit-appearance: none;
        appearance: none;
        -moz-appearance: none;
        background-color: #009f3c;
        color: white;
        border-radius: 15px;
        border: none;
        padding: 5px 20px;
        font-size: 15px;
        margin-top: 10px;
        font-family: Roboto, sans-serif;
        outline: none;

        &:hover {
            background-color: darken(#009f3c, 5%);
            cursor: pointer;
        }
    }

    label {
        font-size: 13px;
    }
}
