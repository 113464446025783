@media screen and (max-width: 750px) {
    .page-footer {
        min-width: 320px;
        text-align: center;
    }

    img {
        width: 175px;
    }

    .page-footer__list {
        grid-template-columns: auto;
        justify-items: center;
        width: 88%;
        padding: 35px 0 30px;
        margin: 0 auto;
    }

    .page-footer__list-item--logo {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        margin: 0 0 5px;
        justify-self: center;
    }

    .page-footer__list-item--adress {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        margin: 0 0 0;
        text-align: center;
        -webkit-align-content: center;
    }

    .page-footer__list-item--contact {
        position: relative;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        margin: 0 0 35px;
        text-align: center;
        -webkit-align-content: center;
    }

    .page-footer__list-item--contact::after {
        display: block;
        position: absolute;
        top: 145px;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        width: 50px;
        height: 1px;
        background-color: white;
    }

    .page-footer__links {
        margin: 0 0 30px;

        justify-content: center;
        .page-footer__links-item:nth-child(4) {
            border-right: 0;
        }
    }

    .page-footer__list-item--links {
        grid-column: 1 / 2;
        grid-row: 4 / 5;
        flex-wrap: wrap;
        width: 100%;
        max-width: 320px;
    }

    .page-footer__list-item--social {
        grid-column: 1 / 2;
        grid-row: 5 / 6;
        margin: 0 0 30px;
        justify-self: center;
    }

    .page-footer__list-item--links2 {
        grid-column: 1 / 2;
        grid-row: 6 / 7;
        margin-bottom: 30px;
    }

    .page-footer__list-item--links2-item {
        padding: 0 9px;
        font-size: 12px;
    }

   .page-footer__list-item--links2 {
        .page-footer__links2-link {
            font-size: 11px;
        }
    }

    .page-footer__list-item--copyright {
        grid-column: 1 / 2;
        grid-row: 7 / 8;
        margin: 0;
        justify-self: center;
    }
}
