.not-found {
    div.container {
        margin-top: 45px;
        max-width: 900px;
    }

    .slick-prev {
        z-index: 10;
        left: 10px !important;
        background-color: white;
        transform: scale(1.3);
        border-radius: 9999px;

        &:before {
            color: #005281 !important;
        }
    }
    .slick-next {
        z-index: 10;
        right: 10px !important;
        background-color: white;
        transform: scale(1.3);
        border-radius: 9999px;

        &:before {
            color: #005281 !important;
        }
    }

    h1 {
        color: #00a329;
    }
    p {
        color: #777;
    }
    h2 {
        a {
            color: #00a329;
            text-decoration: none;
            transition: 0.2s ease-in-out;
        }
        max-width: 300px;
        border: 2px solid #00a329;
        text-align: center;
        margin: 50px auto 10px auto;
        transition: 0.2s ease-in-out;
        border-radius: 5px;

        &:hover {
            background-color: #005281;
            border: 2px solid #005281;
            color: white;
            transition: 0.2s ease-in-out;
            cursor: pointer;
            border-radius: 10px;

            a {
                color: white;
                transition: 0.2s ease-in-out;
            }
        }
    }
}
