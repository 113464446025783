header {
    display: block;

    .dropbtn {
        background-color: #005281;
        color: white;
        padding: 16px;
        font-size: 21px;
        border: none;
    }

    .dropbtn--index {
        background-color: transparent;
    }

    .dropdown {
        position: relative;
        display: inline-block;

        &:after {
            content: 'expand_more';
            color: white;
            font-family: 'Material Icons', sans-serif;
            font-size: 26px;
            margin-left: -10px;
            vertical-align: middle;
        }
    }

    .dropdown-content {
        display: none;
        position: absolute;
        min-width: 130px;
        text-align: left;
        font-size: 18px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        background-color: #0077b5;
    }

    .dropdown-content a {
        color: white;
        padding: 8px 12px;
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {
        background-color: #005281;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .dropdown:hover .dropbtn {
        //background-color: #3e8e41;
    }
}

.background-image {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 1080px;
    background-color: #0092ff;
    background-image: url(../img/foto-home-1920x1080.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.modal-backdrop {
    opacity: 0.5 !important;
}

.modal-dialog {
    margin: 0 0 0 auto !important;
}

.wasvg {
    width: 50px;
    height: 50px;

    &:hover {
        cursor: pointer;
    }
}

.modal-question__wrapper {
    position: relative;
    .wasvg {
        position: absolute;
        bottom: 0;
    }
}
