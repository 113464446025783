.in-action__heading {
    text-transform: none;
    font-family: 'Roboto Condensed';
    font-size: 3.1rem;
    font-weight: 100;
    letter-spacing: 0.3px;
    color: #009f3c;
}

.in-action__subheading {
    font-family: Roboto;
    font-size: 1.5rem;
    font-weight: 400;
    color: #686867;
    margin: 0 auto 25px;
}

.in-action__gallery {
    max-width: 1190px;
    margin: auto auto 50px;
    display: flex;
    padding: 5px;
    background-color: #00a329;
    border-radius: 5px;
    box-shadow: 6px 6px 15px 0 #5f5f5f;

    .image-gallery-content {
        background-color: white;
    }

    .image-gallery-thumbnail {
        width: 95px;
    }
    .image-gallery-thumbnails-container {
        margin-top: 5px;
    }
}

.in-action__gallery--right {
    width: 250px;
    background-color: #00a329;
    text-align: left;
    padding: 10px 15px 0 15px;
    color: white;
    h1 {
        margin: 0;
        text-transform: none;
    }
    p {
        font-size: 14px;
        transition-duration: 0.5s;
    }
    h2 {
        font-size: 18px;
    }
}

.in-action__gallery--left {
    width: 900px;
}

.desktop-only {
    display: block;
}
.mobile-only {
    display: none;
}

@media screen and (min-width: 1201px) {
    .in-action {
        .comparison__contact-container--desktop {
            display: block;

            .comparison__button {
                margin: 0 0 20px;
                background-color: white;
                color: #686867;
                font-size: 1rem;
                font-weight: 700;
                text-decoration: none;
                padding: 10px 20px 10px 50px;
                width: 180px;
                height: 19px;
            }
        }
    }
}

// Mobile styling ++
@media screen and (max-width: 1200px) {
    .desktop-only {
        display: none;
    }
    .mobile-only {
        display: block;
        color: #686867;
    }

    .project-title {
        color: #009f3c;
    }

    .contact-button-container {
        display: flex;
        justify-content: space-evenly;
    }
    .mobile-contact {
        background-color: #005281;
        color: white;
        font-size: 18px;
        font-weight: 500;
        padding: 1px 1px 15px;
    }

    .read-more-button {
        width: 120px;
        border: 3px solid #009f3c;
        background-color: white;
        text-align: center;
        border-radius: 25px;
        padding: 10px 5px;
        text-transform: none;
        color: #525251;
        font-weight: 600;
        margin: auto;
    }

    .in-action__heading {
        color: #009f3c;
        width: 100%;
        background-color: white;
        font-size: 24px;
        margin: 0;
        padding: 20px 0;
        font-weight: 100;
        font-family: 'Roboto Condensed';
    }

    .in-action__subheading {
        font-size: 15px;
        margin-top: 20px;
    }

    .in-action__gallery {
        flex-flow: column;
        padding: 0;
        margin: 0;
        background-color: white;
        box-shadow: none;

        .in-action__gallery--left {
            width: 100%;
        }

        .in-action__gallery--right {
            width: auto;
            padding: 40px 40px 15px;
            color: black;
            background-color: white;
        }

        .image-gallery-thumbnails-wrapper {
            display: none;
        }

        .image-gallery-slide-wrapper {
            border: 5px solid #009f3c;
        }

        .image-gallery-bullets {
            bottom: -30px;

            button {
                border: 1px solid #009f3c;
                background-color: white;
                box-shadow: none;
                padding: 4px;

                &.active {
                    background-color: #009f3c;
                }
            }
        }
    }
}
