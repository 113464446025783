@media screen and (min-width: 750px) {
    .team__list-mobile {
        display: none;
    }

    .team-member__contact-container {
        display: none;
    }

    .team {
        .wasvg {
            width: 39px;
            height: 39px;
        }
        .whatsapp-link {
            display: inline-block;
            vertical-align: top;
            transform: translate(30px, 0px);
        }
    }
}

.team {
    background-color: white;
}

.team__wrapper {
    width: 70%;
    max-width: 1300px;
    margin: 0 auto 0;
}

.team__heading {
    text-transform: none;
    font-family: 'Roboto Condensed';
    font-size: 3.1rem;
    font-weight: 100;
    letter-spacing: 0.3px;
    color: #009f3c;
}

.team__list-desktop {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.team-member {
    width: 334px;
    border: 2px solid #005281;
    box-shadow: 5px 6px 6px 0 #cecece;
    transition-duration: 0.5s;
    margin: 0 10px 30px;

    .contact-button {
        font-weight: normal;
    }
}

.team-member__container {
    background-color: white;
}

.team-member__photo {
    display: block;
    width: 257px;
    height: auto;
    margin: 0 auto;

    &.technische-dienst {
        width: 334px;
    }
}

.team-member__heading {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0.4px;
    color: #009f3c;
    margin: 15px 0;
    overflow: hidden;
    white-space: nowrap;
}

.team-member__info {
    background-color: #005281;
    color: white;
    position: relative;
}

.team-member__name {
    font-family: Roboto;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0 0 6px;
    padding-top: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.team-member__function {
    display: block;
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-style: italic;
    letter-spacing: 0.28px;
    margin-bottom: 14px;
}

.team-member__contact {
    margin-bottom: 18px;
}

.team__direction {
    display: block;
    color: #009f3c;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 2.2rem;
    font-weight: 100;
    text-transform: none;
    letter-spacing: 0.58px;
    margin-bottom: 80px;
    margin-top: 80px;
}

@media (max-width: 1700px) {
    .team__wrapper {
        width: 95%;
        margin: 0 auto;
    }
}

@media (max-width: 1160px) {
    .team__wrapper {
        width: 100%;
        max-width: 900px;
    }

    .team-member {
        width: 270px;
    }

    .team-member__heading {
        font-size: 1.1rem;
    }

    .team-member__photo {
        height: auto;

        &.technische-dienst {
            width: 270px;
            margin-top: 64px;
        }
    }

    .team-member__contact {
        display: none;
    }

    .team-member__contact-container {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
    }

    .team-member__contact-mobile {
        display: inline-block;
        margin: 0 0px 20px;
    }

    .team .whatsapp-link {
        float: right;
        transform: translate(-3px, 0px);
    }
}

@media (max-width: 900px) {
    .team-member {
        width: 220px;
    }

    .team-member__name {
        font-size: 1.2rem;
    }

    .team-member__contact-container {
        //flex-direction: column;
    }

    .team-member__contact-mobile {
        width: 25%;
        margin: 0 auto 10px;
    }

    .team .whatsapp-link {
        transform: translate(5px, 0px);
        margin-bottom: 5px;
        float: unset;
    }

    .team-member__photo {
        width: 140px;
        height: auto;

        &.technische-dienst {
            width: 182px;
            margin-top: 0;
        }
    }

    .team-member__247 {
        .team-member__photo {
            width: 140px;
            height: 140px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
